import store from '../../store/index';
 

const measures_icon = (measure) => {
  const power = ['DailyEnergy', 'TotalEnergy'];

  if (power.indexOf(measure) > -1) {
    return 'kWh';
  }

  if (measure == 'PerformanceRatio') {
    return '%';
  }

  if (measure == 'Temperature') {
    return '°C';
  }

  if (measure == 'DailyEfficiency') {
    return 'kWh/kWp';
  }

  if (measure == 'DCVoltage') {
    return 'V dc';
  }

  if (measure == 'DCCurrent') {
    return 'A';
  }

  const power1 = ['IstantaneousPower', 'TheoreticalPower'];
  if (power1.indexOf(measure) > -1) {
    return 'kW';
  }

  return '';
};
function isNull(val) {
 
  if (typeof val === 'undefined') {
    return true;
  }

  if (typeof val === 'null') {
    return true;
  }
   return false;
}
const measures_icon1 = (measure) => {
  const power = ['DailyEnergy', 'TotalEnergy'];

  if (power.indexOf(measure) > -1) {
    return 'MW';
  }

  if (measure == 'PerformanceRatio') {
    return '%';
  }

  if (measure == 'Temperature') {
    return '°C';
  }

  if (measure == 'DailyEfficiency') {
    return 'kWh/kWp';
  }

  if (measure == 'DCVoltage') {
    return 'V dc';
  }

  if (measure == 'DCCurrent') {
    return 'A';
  }

  const power1 = ['IstantaneousPower', 'TheoreticalPower'];
  if (power1.indexOf(measure) > -1) {
    return 'MW';
  }

  return '';
};

const measures_max = (measure) => {
  
  let max = 100;
  if (measure.indexOf('PerformanceRatio') > -1) {
    max = 150;
  }

  if (measure.indexOf('TheoreticalPower') > -1) {
    max = 1800;
  }

  if (measure.indexOf('IstantaneousPower') > -1) {
    max = 1800;
  }
  if (measure.indexOf('Temperature') > -1) {
    max = 80;
  }
  if (measure.indexOf('PanelTemp1') > -1) {
    max = 80;
  }
  if (measure.indexOf('Temp') > -1) {
    max = 80;
  }

  if (measure.indexOf('Irradiation') > -1) {
    max = 1200;
  }
  if (measure.indexOf('Irr') > -1) {
    max = 1200;
  }
  if (measure.indexOf('IrradiationCalculated') > -1) {
    max = 1200;
  }

  return max;
};

const setSortType = (reportTypeModel) => {
  let sortType;

  if (reportTypeModel == 'Yearly') {
    sortType = 'Year';
  }
  if (reportTypeModel == 'Monthly') {
    sortType = 'Month';
  }

  if (reportTypeModel == 'Daily') {
    sortType = 'Day';
  }

  if (reportTypeModel == 'Hourly') {
    sortType = 'Hour';
  }
  if (reportTypeModel == 'Minute') {
    sortType = 'Hour';
  }
  return sortType;
};

const setMeasures = (measure, data, total) => {
  let tmp_val = 0;
  const totalValMeasures = ['DailyEnergy'];

  if (totalValMeasures.indexOf(measure.value) > -1) {
    if (data) {
      tmp_val = parseFloat(data[measure.value]).toFixed(0);
    } else {
      tmp_val = 0;
    }
  } else if (data) {
    tmp_val = parseFloat(data[measure.value]).toFixed(0);
  } else {
    tmp_val = 0;
  }
  return tmp_val;
};

const setmeasures = (data,length=0) => {
  let tmp_val = 0;
 
     
  if (isNull(data) === false) {
    
    tmp_val = parseFloat(data).toFixed(length);
  } else {
    tmp_val = 0;
  }
  
  return tmp_val;
};


const dateListFonks = (item, reportTypeModel) => {
  let date;

  if (reportTypeModel == 'Minute') {
    let hh;
    let mm;
    item.Hour < 10 ? hh = `0${item.Hour}` : hh = `${item.Hour}`;
    item.MinuteGrup < 10 ? mm = `0${item.MinuteGrup}` : mm = `${item.MinuteGrup}`;
    date = `${hh}:${mm}`;
  }

  if (reportTypeModel == 'Hourly') {
    let hh;
    item.Hour < 10 ? hh = `0${item.Hour}` : hh = `${item.Hour}`;
    date = `${hh}`;
  }

  if (reportTypeModel == 'Daily') {
    let m; let
      d;
    item.Month < 10 ? (m = `0${item.Month}`) : (m = `${item.Month}`);
    item.Day < 10 ? (d = `0${item.Day}`) : (d = `${item.Day}`);
    date = `${item.Year}-${m}-${d}`;
  }
  if (reportTypeModel == 'Weekly') {
    date = `Hafta-${item.Week}`;
  }

  if (reportTypeModel == 'Monthly') {
    let m;
    item.Month < 10 ? (m = `0${item.Month}`) : (m = `${item.Month}`);
    date = `${item.Year}-${m}`;
  }
  if (reportTypeModel == 'Yearly') {
    date = item.Year;
  }

  return date;
};

export function getStoreData() {
  return new Promise((resolve) => {
    store.watch(
      (state) => state.santral.generalStatsData,
      (newValue, oldValue) => {
        resolve(newValue);
      }, {
        deep: true,
      },
    );
  });
}

const setData = (item, measure, total) => {
  const formulesMeas = ['EquivalentTrees', 'CO2Avoided', 'BarrelsofOil'];

  let val = 0;

  if (formulesMeas.indexOf(measure) > -1) {
    let value = 0;
    item.DailyEnergy = parseFloat(item.DailyEnergy);

    if (item.DailyEnergy) {
      if (item.DailyEnergy > total) {
        value = item.DailyEnergy - total;
      }

      if (measure == 'EquivalentTrees') {
        val = Math.round(value / 230.73, 5);
      }

      if (measure == 'CO2Avoided') {
        val = Math.round(value * 0.65, 5);
      }

      if (measure == 'BarrelsofOil') {
        val = Math.round(value * 0.00058833, 5);
      }
    } else {
      val = 0;
    }
  } else {
    val = Math.round(parseFloat(item[measure]), 1);
  }

  return val;
};

const sortBy = (arr, keys, splitKeyChar = '~') => arr.sort((i1, i2) => {
  const sortStr1 = keys.reduce((str, key) => str + splitKeyChar + i1[key], '');
  const sortStr2 = keys.reduce((str, key) => str + splitKeyChar + i2[key], '');
  return sortStr1.localeCompare(sortStr2);
});
const colors = () => [
  '#e22129',
  '#f27425',
  '#fbd00e',
  '#a7c13a',
  '#51a146',
  '#2ea3a3',
  '#2480c3',
  '#8d275f',
  '#FF9661',
  '#46C7A8',
  '#68C17A',
  '#F18EE1',
  '#72AEFF',
  '#FF8A7E',
  '#ED0A3F',
  '#FFFF66',
  '#EE34D2',
  '#FF8833',
  '#299617',
  '#5DADEC',
  '#8D4E85',
  '#0066FF',
  '#6a2d8d',
  '#E62E6B',
  '#87FF2A',
  '#FD5B78',
  '#C5E17A',
  '#FF9980',
  '#B5B35C',
  '#8FD8D8',
  '#FF6D3A',
  '#B05C52',
  '#828E84',
  '#C53151',
  '#4F209C',
  '#2E79DD',
  '#6BE3D5',
  '#DC3E41',
  '#E56725',
  '#FCCE38',
];

export default {
  sortBy,
  measures_icon,
  measures_max,
  measures_icon1,
  setSortType,
  dateListFonks,
  colors,
  getStoreData,
  setData,
  setMeasures,
  setmeasures,
  isNull
};
